// App.js
import React, { useState, useEffect } from 'react';
import './App.css';

const API_URL =
  'https://maple-chat-backend-prod.wonderfulwave-130a2e3b.southeastasia.azurecontainerapps.io/v1/chat/send';

function App() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [showPhonePopup, setShowPhonePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Load phone and chat history from local storage on first render
  useEffect(() => {
    const savedPhone = localStorage.getItem('phoneNumber');
    const savedChat = localStorage.getItem('chatHistory');
    if (savedPhone) {
      setPhoneNumber(savedPhone);
    } else {
      setShowPhonePopup(true); // Show popup if no phone number is set
    }
    if (savedChat) {
      setChatHistory(JSON.parse(savedChat));
    }
  }, []);

  // Save phone and chat history to local storage on updates
  useEffect(() => {
    if (phoneNumber) {
      localStorage.setItem('phoneNumber', phoneNumber);
    }
    if (chatHistory.length > 0) {
      localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
    }
  }, [phoneNumber, chatHistory]);

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    const newChat = { sender: 'You', text: message };
    setChatHistory((prev) => [...prev, newChat]);
    setMessage('');
    setIsLoading(true);

    try {
      const response = await fetch(
        API_URL,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phone: phoneNumber, text: message }),
        },
        1 * 60 * 1000
      );

      const data = await response.json();
      const formattedText = data.user
        ? data.user.replace(/\n/g, '<br />')
        : 'No response';
      const botReply = { sender: 'Maple', text: formattedText };
      setChatHistory((prev) => [...prev, botReply]);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleSendMessage();
  };

  const handlePhoneSubmit = () => {
    if (phoneNumber.trim()) setShowPhonePopup(false);
  };

  return (
    <div className="app">
      {/* Popup for phone number */}
      {showPhonePopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Enter Your Phone Number</h2>
            <input
              type="text"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <button onClick={handlePhoneSubmit}>Save</button>
          </div>
        </div>
      )}

      {/* Phone Number Display */}
      <div className="phone-display">
        {phoneNumber ? (
          <>
            <span>Phone: {phoneNumber}</span>
            <button onClick={() => setShowPhonePopup(true)}>Edit</button>
          </>
        ) : null}
      </div>

      <div className="chat-container">
        <div className="chat-history">
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={`chat-message ${
                chat.sender === 'You' ? 'sent' : 'received'
              }`}
              dangerouslySetInnerHTML={{
                __html: `<strong>${chat.sender}:</strong> ${chat.text}`,
              }}
            />
          ))}
          {isLoading && (
            <div className="chat-message received">
              <strong>Maple:</strong> <em>Typing...</em>
            </div>
          )}
        </div>

        <div className="chat-input">
          <input
            type="text"
            placeholder="Type your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
}

export default App;
